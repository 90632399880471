import { Link } from "react-router-dom";
import "./footer.scss";
export default function Footer() {
  return (
    <footer>
      <div>
        <h4>Contact Us</h4>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
            <i className="fas fa-map-marker-alt"></i> ActivityCloud by Klikkit
          </span>
          <span>Co/ Colab 3.0 - Kongegata 29</span>
          <span>3257 - Larvik Norway</span>
        </div>
        <div>
          <i className="fas fa-envelope"></i> post@klikkit.no
        </div>
        <div>
          <h4>Follow Us</h4>
          <i className="fab fa-facebook" />
          <i className="fab fa-twitter" />
          <i className="fab fa-instagram" />
        </div>
      </div>
      <div>
        <h4>More information?</h4>
      </div>
      <div>
        <h4>Legal</h4>
        <ul>
          <li>
            <Link to="/terms">Terms of Service</Link>
          </li>
          <li>
            <Link to="/gdpr">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/payment">Payment: Terms and Conditions</Link>
          </li>
        </ul>
        <h4>Company</h4>
        <ul>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="/map">Map</Link>
          </li>
          <li>
            <Link to="https://klikkit.no">Klikkit.no</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}
