import React from "react";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";

export default function Terms() {
  return (
    <main>
      <Nav />
      <div
        style={{
          width: "50vw",
          minWidth: "300px",
          position: "relative",
          margin: "24px auto",
          display: "flex",
          flexDirection: "column",
          padding: "24px",
          backgroundColor: "white",
          gap: "24px",
        }}
      >
        <h1>Terms and Conditions - ActivityCloud by Klikkit</h1>
        <span>Last Updated: 11th of April 2024</span>
        <p>
          Welcome to ActivityCloud by Klikkit. By accessing or using our
          services, you agree to be bound by the terms and conditions set forth
          in this agreement. If you do not agree to these terms, please do not
          use our services.
        </p>
        <div>
          <h2>Use of Our Services</h2>
          <p>
            You may use our services only as permitted by law and according to
            these terms. You must not misuse our services. For example, do not
            interfere with our services or try to access them using a method
            other than the interface and the instructions that we provide.
          </p>
          <p>
            If you use our services on behalf of a company, that company accepts
            these terms. It will hold harmless and indemnify ActivityCloud by
            Klikkit, and Klikkit AS and its affiliates, officers, agents, and
            employees from any claim, suit or action arising from or related to
            the use of the services or violation of these terms, including any
            liability or expense arising from claims, losses, damages, suits,
            judgments, litigation costs and attorneys’ fees.
          </p>
          <p>
            We may suspend or stop providing our services to you if you do not
            comply with our terms or policies or if we are investigating
            suspected misconduct.
          </p>
        </div>
        <div>
          <h2>Privacy</h2>
          <p>
            Our Privacy Policy explains how we handle your personal data and
            protect your privacy when you use our services. By using our
            services, you agree that we can use such data according to our
            privacy policies.
            <Link to="/gdpr">Read our Privacy Policy</Link>
          </p>
        </div>
        <div>
          <h2>Your Content in Our Services</h2>
          <p>
            Some of our services allow you to upload, submit, store, send, or
            receive content. You retain ownership of any intellectual property
            rights that you hold in that content. In short, what belongs to you
            stays yours.
          </p>
        </div>
        <div>
          <h2>Modifying and Terminating Our Services</h2>
          <p>
            We are constantly changing and improving our services. We may add or
            remove functionalities or features, and we may suspend or stop a
            service altogether.
          </p>
          <p>
            You can stop using our services at any time, although we'll be sorry
            to see you go. ActivityCloud by Klikkit may also stop providing
            services to you or add or create new limits to our services at any
            time. Unless otherwise stated, we will provide you with notice if we
            stop providing a service.
          </p>
          <p>
            Some companies, people and/or organizations might have other legal
            rights as additional terms or requirements. These terms are in
            addition to or amend the terms in this document and will always be
            followed.
          </p>
        </div>
        <div>
          <h2>Warranties and Disclaimers</h2>
          <p>
            We provide our services using a commercially reasonable level of
            skill and care, but there are certain things that we don't promise
            about our services.
          </p>
          <p>
            Except as expressly set out in these terms, neither ActivityCloud by
            Klikkit, nor Klikkit AS, nor its suppliers or distributors, make any
            specific promises about the services. For example, we don't make any
            commitments about the content within the services, the specific
            functions of the services, or their reliability, availability, or
            ability to meet your needs. We provide the services “as is”.
          </p>
        </div>
        <div>
          <h2>Liability for Our Services</h2>
          <p>
            When permitted by law, ActivityCloud by Klikkit, and Klikkit AS’s
            suppliers and distributors, will not be responsible for lost
            profits, revenues, or data.
          </p>
        </div>
        <div>
          <h2>About These Terms</h2>
          <p>
            We may modify these terms or any additional terms that apply to a
            service. You should look at the terms regularly.
          </p>
          <p>
            Changes will not apply retroactively and will become effective no
            sooner than fourteen days after they are posted. However, changes
            addressing new functions for a service or changes made for legal
            reasons will be effective immediately.
          </p>
        </div>
      </div>
      <Footer />
    </main>
  );
}
