import React from "react";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

export default function PaymentTerms() {
  return (
    <main>
      <Nav />
      <div
        style={{
          width: "50vw",
          minWidth: "300px",
          position: "relative",
          margin: "24px auto",
          display: "flex",
          flexDirection: "column",
          padding: "24px",
          backgroundColor: "white",
          gap: "24px",
        }}
      >
        <h1>Payment Terms and Conditions - ActivityCloud by Klikkit</h1>
        <span>Last Updated: 11th of April 2024</span>
        <p>
          Welcome to ActivityCloud by Klikkit. By accessing or using our
          services, you agree to be bound by the payment terms and conditions
          set forth in this agreement. If you do not agree to these terms,
          please do not use our services.
        </p>
        <div>
          <h2>Payment to Organizations, People and or Companies</h2>
          <p>
            We have a payment system that allows you to pay for activities and
            events. In the cases where you need to pre-pay an activity or event,
            you will be charged when you sign up for the activity or event. If
            you do not show up for the activity or event, you will not be
            refunded. If the activity or event is canceled, you will be
            refunded.
          </p>
          <p>
            As our platform is created for activities and experiences the
            service is provided by organizations, people and or companies. These
            organizations, people and or companies are responsible for the
            activities and events they create. ActivityCloud by Klikkit is not
            responsible for the activities and events created by organizations,
            people and or companies.
          </p>
          <p>
            ActivityCloud by Klikkit has no access to or control over the
            payment transactions made between organizations, people and or
            companies and users. We are not responsible for the actions of
            organizations, people and or companies. We are not responsible for
            the quality, safety, morality or legality of any aspect of the
            activities and events created by organizations, people and or
            companies.
          </p>
          <p>
            We do however have a feedback system in place that allows users to
            rate and review activities and events. We encourage users to rate
            and review activities and events to help other users make informed
            decisions. - And any organization, person and or company that
            receives negative feedback will be investigated and may be removed
            from our platform.
          </p>
          <p>
            If by any point we discover any illegal, unsafe or immoral activity
            or event we will remove the activity or event and the organization,
            person and or company responsible for the activity or event from our
            platform.
          </p>
        </div>
        <div>
          <h2>Your Payment Information</h2>
          <p>
            We provide all payment information from the payment processor
            directly inside your profile. You can access your previous payments
            and invoices from your profile. We do not store any payment
            information on our servers. All payment information is stored by our
            payment processors.
          </p>
        </div>
        <div>
          <h2>Your Rights</h2>
          <p>
            If you have experienced any issues with a service, payment or
            activity, please contact us at{" "}
            <a href="mailto:post@klikkit.no">post @ klikkit.no</a>, and we will
            do our best to help you resolve the issue. We do not have access to
            or control over the payment transactions made between organizations,
            people and or companies and users. But we will do our best to help
            you resolve the issue in a fair, just and timely manner.
          </p>
        </div>
        <div>
          <h2>Payment to ActivityCloud</h2>
          <p>
            Payment is required to access certain features of the ActivityCloud
            platform. Payment can be made by credit card or other payment
            methods as made available by Klikkit AS. Our payment processor is
            Stripe. By using our services, you agree to be bound by Stripe's
            terms and conditions.
          </p>
          <p>
            We charge a fee for the use of our services. The fee is charged when
            you add funds to your account. The fee is non-refundable. The fee is
            charged to cover the costs of running the platform. You are able to
            transfer the funds to another user or organization, person and or
            company.
          </p>
        </div>
      </div>
      <Footer />
    </main>
  );
}
