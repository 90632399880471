import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getActivities = async (dateFrom, dateUntil) => {
  console.log(apiUrl + `activity/date-range/${dateFrom}/${dateUntil}`);
  const response = await axios.get(
    apiUrl + `activity/date-range/${dateFrom}/${dateUntil}`
  );
  return response;
};

export const getActivityById = async (id) => {
  const response = await axios.get(apiUrl + `activity/${id}`);
  return response;
};

export const createNewActivity = async (activity, token) => {
  const response = await axios.post(apiUrl + "activity", activity, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response;
};
