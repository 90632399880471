import React, { useState, useEffect, useRef, useContext } from "react";
import { getCategories } from "../../service/categoryService";
import { useNavigate } from "react-router-dom";
import { createNewActivity } from "../../service/activityService";
import ActivityContext from "../../context/ActivityContext";
import Toast from "../../components/Toast";
import Loader from "../../components/Loader";
import "./createActivity.scss";
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js"; // Import like this for better bundling

mapboxgl.accessToken =
  "pk.eyJ1IjoiaGtvdmVycmlkZSIsImEiOiJjbHRvcm9vZTQwbWZiMmlueWg0ajR4djJpIn0.4W0ZfV78s1w0swKNtLOYCA";

export default function CreateActivity() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [activityDetails, setActivityDetails] = useState({});
  const [marker, setMarker] = useState(null);
  const mapContainer = useRef(null);
  const mapData = useRef(null);
  const dateRef = useRef(null);
  const timeRef = useRef(null);
  const durationDaysRef = useRef(null);
  const durationHoursRef = useRef(null);
  const durationMinutesRef = useRef(null);
  const { fetchToken } = useContext(ActivityContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState({
    show: false,
    type: "",
    message: "",
    title: "",
    reference: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let date = dateRef.current.value;
      let time = timeRef.current.value;

      let hours = parseInt(time.split(":")[0]);
      let minutes = parseInt(time.split(":")[1]);
      let start_time = hours * 60 + minutes;
      let location = {
        longitude: marker.getLngLat().lng,
        latitude: marker.getLngLat().lat,
        altitude: 0,
      };

      let inputData = {
        name: activityDetails.name,
        description: activityDetails.description,
        languages: [],
        location: location,
        date: date,
        start_time: start_time,
        duration: 0,
        categories: [selectedCategory.id],
        marker_color: selectedCategory.color,
        price_rules: [],
        currency: "NOK",
        image_url: "",
        max_attendees: parseInt(activityDetails.max_attendees),
      };

      let durationDays = parseInt(durationDaysRef?.current?.value ?? 0);
      let durationHours = parseInt(durationHoursRef?.current?.value ?? 0);
      let durationMinutes = parseInt(durationMinutesRef?.current?.value ?? 0);

      // Parse down to minutes.
      let calculatedDuration = 0;
      if (durationDays > 0) {
        calculatedDuration += durationDays * 24 * 60;
      }
      if (durationHours > 0) {
        calculatedDuration += durationHours * 60;
      }
      if (durationMinutes > 0) {
        calculatedDuration += durationMinutes;
      }
      // Duration command (into API) is in minutes.
      // The duration returned is in nanoseconds.

      inputData.duration = calculatedDuration;

      const res = await createNewActivity(inputData, fetchToken());
      if (res.status === 201) {
        setFeedback({
          show: true,
          type: "success",
          message: "Activity created successfully",
          title: "Success",
          reference: res?.data,
        });
      } else {
        setFeedback({
          show: true,
          type: "error",
          message: "Failed to create activity",
          title: "Error",
        });
      }
    } catch (err) {
      console.error(err);
      setFeedback({
        show: true,
        type: "error",
        message: "Failed to create activity",
        title: "Error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await getCategories();
      setCategories(res?.data ?? []);
    };
    fetchCategories();

    return () => {
      setCategories([]);
    };
  }, []);

  // Build map
  useEffect(() => {
    if (!mapContainer.current) return; // Exit if the container is not ready

    let startLocation = [10.0388, 59.049];
    if (!mapData.current) {
      mapData.current = new mapboxgl.Map({
        attributionControl: false,
        container: mapContainer.current,
        center: startLocation,
        zoom: 5,
        bearing: 0,
        pitch: 0,
      });

      mapData.current.on("style.load", () => {
        mapData.current.setConfigProperty("basemap", "lightPreset", "day");
      });
    }

    // Define the click handler as a named function
    const addMarkerOnClick = (e) => {
      let newPos = [e.lngLat.lng, e.lngLat.lat];
      setMarker((prevMarker) => {
        if (prevMarker) {
          prevMarker.setLngLat(newPos); // Update existing marker position
          return prevMarker;
        }

        console.log("Creating new marker");
        const newMarker = new mapboxgl.Marker({
          color: "#ccc",
        })
          .setLngLat(newPos)
          .addTo(mapData.current);
        return newMarker;
      });
    };

    // Remove previous click listeners to prevent duplicates
    mapData.current.off("click", addMarkerOnClick);

    // Attach the new click listener
    mapData.current.on("click", addMarkerOnClick);

    // Cleanup function
    return () => {
      if (mapData.current) {
        mapData.current.off("click", addMarkerOnClick);
        // Optionally, remove the marker from the map if you are cleaning up
        setMarker((currentMarker) => {
          if (currentMarker) {
            currentMarker.remove();
          }
          return null; // Reset marker state
        });
      }
    };
  }, []); // Reruns the effect only if selectedCategory changes

  useEffect(() => {
    if (selectedCategory) {
      setMarker((prevMarker) => {
        if (prevMarker) {
          prevMarker.getElement().querySelector("path").style.fill =
            selectedCategory.color;
          return prevMarker;
        }
        return prevMarker;
      });
    }
  }, [marker, selectedCategory]);

  return (
    <div>
      <div id="activity_creator">
        <div
          style={{
            display: "flex",
            gap: "8px",
            flexDirection: "column",
            padding: "12px",
          }}
        >
          <h3>Categories</h3>
          {categories?.map((category) => {
            let isSelected = category.id === selectedCategory?.id ?? false;
            return (
              <div
                className={`category__card ${isSelected ? "active" : ""}`}
                key={category._id}
                style={
                  isSelected
                    ? {
                        borderColor: category.color,
                      }
                    : {}
                }
                onClick={() => {
                  setSelectedCategory(category);
                }}
              >
                <span>{category.name}</span>
                <span
                  style={{
                    color: category.color,
                  }}
                >
                  <i className={category.icon ?? "fas fa-question"}></i>
                </span>
              </div>
            );
          })}
        </div>
        <div ref={mapContainer} id="map__container"></div>
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          style={{
            display: "flex",
            gap: "8px",
            flexDirection: "column",
            padding: "12px",
          }}
        >
          <h3>Activity Details</h3>
          <div>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              value={activityDetails?.name ?? ""}
              onChange={(e) =>
                setActivityDetails({ ...activityDetails, name: e.target.value })
              }
            />
          </div>
          <div>
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              value={activityDetails?.description ?? ""}
              onChange={(e) =>
                setActivityDetails({
                  ...activityDetails,
                  description: e.target.value,
                })
              }
            ></textarea>
          </div>
          <div>
            <label htmlFor="date">Date</label>
            <input type="date" id="date" ref={dateRef} />
          </div>
          <div>
            <label htmlFor="time">Time</label>
            <input type="time" id="time" ref={timeRef} />
          </div>
          <div>
            <h4>Set Activity Duration</h4>
            <div className="duration__container">
              <div>
                <span>Days</span>
                <input type="number" ref={durationDaysRef} />
              </div>
              <div>
                <span>Hours</span>
                <input type="number" ref={durationHoursRef} />
              </div>
              <div>
                <span>Minutes</span>
                <input type="number" ref={durationMinutesRef} />
              </div>
            </div>
          </div>
          <div>
            <label htmlFor="max_attendees">Max Attendees</label>
            <input
              type="number"
              id="max_attendees"
              value={activityDetails?.max_attendees ?? 1}
              onChange={(e) =>
                setActivityDetails({
                  ...activityDetails,
                  max_attendees: e.target.value,
                })
              }
            />
          </div>
          <button type="submit">Create Activity</button>
        </form>
      </div>
      {loading && <Loader />}
      {feedback.show && (
        <Toast
          message={feedback.message}
          type={feedback.type}
          title={feedback.title}
          close={() => {
            if (feedback.type === "success" && feedback.reference) {
              navigate("/map/" + feedback.reference, { replace: true });
            }
            setFeedback({ ...feedback, show: false });
          }}
        />
      )}
    </div>
  );
}
