import React, { useContext, useEffect, useState } from "react";
import ActivityContext from "../../context/ActivityContext";
import { useParams, useNavigate } from "react-router-dom";

// Used by owners/organizations to manage their activities.
// You have to be registered and login in to access this page.

export default function Organization() {
  const { id } = useParams();
  const [orgDetails, setOrgDetails] = useState({});
  const { fetchToken } = useContext(ActivityContext);
  const navigate = useNavigate();

  useEffect(() => {
    const res = fetchToken();
    console.log(res);
    if (!res) {
      console.log("No token found");
      navigate("/login");
    }
  }, [fetchToken, navigate]);

  if (!id) {
    return (
      <div>
        <h1>Create new Organization</h1>
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <label>
            <span>Name</span>
            <input
              type="text"
              value={orgDetails?.name ?? ""}
              onChange={(e) => {
                setOrgDetails({ ...orgDetails, name: e.target.value });
              }}
            />
          </label>
          <label>
            <span>Logo</span>
            <input
              type="text"
              value={orgDetails?.logo ?? ""}
              onChange={(e) => {
                setOrgDetails({ ...orgDetails, logo: e.target.value });
              }}
            />
          </label>
          <label>
            <span>Phone</span>
            <input
              type="text"
              value={orgDetails?.phone ?? ""}
              onChange={(e) => {
                setOrgDetails({ ...orgDetails, phone: e.target.value });
              }}
            />
          </label>
          <label>
            <span>Email</span>
            <input
              type="text"
              value={orgDetails?.email ?? ""}
              onChange={(e) => {
                setOrgDetails({ ...orgDetails, email: e.target.value });
              }}
            />
          </label>
          <button type="submit">
            <i className="fas fa-plus"></i>
            <span>Create</span>
          </button>
        </form>
        <span>Then create and redirect</span>
      </div>
    );
  }
  return (
    <div>
      <h1>Manage Organization</h1>
      <p>{id}</p>
      <ul>
        <li>Name</li>
        <li>Description</li>
        <li>Languages</li>
        <li>Location</li>
        <li>Address</li>
        <li>Logo</li>
        <li>Website</li>
        <li>Phone</li>
        <li>Email</li>
        <li>Links</li>
        <li>Members</li>
        <li>Administrations</li>
      </ul>
    </div>
  );
}
