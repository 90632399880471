import React, { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import About from "./pages/About";
import SignUp from "./pages/Signup";
import Login from "./pages/Login";
import CreateActivity from "./pages/CreateActivity";
import Gdpr from "./pages/legal/Gdpr";
import Terms from "./pages/legal/Terms";
import PaymentTerms from "./pages/legal/PaymentTerms";
import Organization from "./pages/Organization";
const Home = lazy(() => import("./pages"));
const Landing = lazy(() => import("./pages/Landing"));
const Error = lazy(() => import("./pages/Error"));
const Map = lazy(() => import("./pages/Map"));
const Profile = lazy(() => import("./pages/Profile"));
const ProfilePayment = lazy(() => import("./pages/Profile/ProfilePayment"));

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: (
      <Suspense fallback={<div>Loading...</div>}>
        <Error />
      </Suspense>
    ),
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Home />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Landing />
          </Suspense>
        ),
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "map",
        element: <Map />,
      },
      {
        path: "map/:id",
        element: <Map />,
      },
      {
        path: "org",
        element: <Organization />,
        children: [
          {
            path: ":id",
            element: <Organization />,
          },
        ],
      },
      {
        path: "/sign-up",
        element: <SignUp />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/profile",
        element: <Profile />,
        children: [
          {
            path: "/profile/payment",
            element: <ProfilePayment />,
          },
        ],
      },
      {
        path: "/create-activity",
        element: <CreateActivity />,
      },
      {
        path: "/gdpr",
        element: <Gdpr />,
      },
      {
        path: "/terms",
        element: <Terms />,
      },
      {
        path: "/payment",
        element: <PaymentTerms />,
      },
    ],
  },
]);

export default function Router() {
  return <RouterProvider router={router} />;
}
