import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getCategories = async () => {
  const response = await axios.get(apiUrl + "category");
  return response;
};

// Require admin login
export const createCategory = async (category, token) => {
  const response = await axios.post(apiUrl + "category", category, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response;
};
