import React from "react";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

export default function Gdpr() {
  return (
    <main>
      <Nav />
      <div
        style={{
          width: "50vw",
          minWidth: "300px",
          position: "relative",
          margin: "24px auto",
          display: "flex",
          flexDirection: "column",
          padding: "24px",
          backgroundColor: "white",
          gap: "24px",
        }}
      >
        <h1>
          General Data Protection Regulation (GDPR) - ActivityCloud by Klikkit
        </h1>
        <span>Last updated: 11th of April 2024</span>
        <div>
          <p>The service is created and maintained by Klikkit AS.</p>
          <p>
            Klikkit AS is a Norwegian company with the organization number 926
            084 615.
          </p>
          <p>
            Our address is: Klikkit AS, c/o Colab 3.0, Kongegata 29, 3257
            Larvik, Norway.
          </p>
          <p>
            ActivityCloud by Klikkit (Klikkit AS), operates within the legal
            frameworks set out by the EU. The GDPR aims to protect individuals'
            personal data and ensure their privacy.
          </p>
        </div>
        <div>
          <h2>Purpose of this document is to:</h2>
          <ul>
            <li>Inform you about the data we collect</li>
            <li>How we use it</li>
            <li>How we protect it</li>
            <li>Your rights</li>
          </ul>
        </div>
        <div>
          <h2>What data do we collect?</h2>
          <p>
            We collect personal data that you provide to us when you sign up for
            our services. This includes your name, email address, and phone
            number. In addition to these, we collect data related to the
            activities and experiences you participate in. This includes:
            <ul>
              <li>
                <strong>Location Data:</strong> GPS coordinates and physical
                addresses associated with the activities and experiences you
                engage in.
              </li>
              <li>
                <strong>Time of Activities:</strong> The specific times and
                dates when you participate in activities, to help in creating a
                timeline of your experiences.
              </li>
              <li>
                <strong>Activity Categories:</strong> Information about the
                types of activities you are interested in or participate in,
                which helps us understand your preferences.
              </li>
              <li>
                <strong>Reviews and Feedback:</strong> Any reviews or feedback
                you provide regarding your experiences. This helps us to
                recommend other activities that might interest you in the
                future.
              </li>
            </ul>
          </p>
          <p>
            Collecting this information allows us to provide a more personalized
            and effective service, tailor recommendations to your preferences,
            and improve the overall quality of the activities offered.
          </p>
          <p>
            We also collect data on how you interact with our services,
            including the activities you view, the ones you sign up for, and the
            ones you attend. This data is used to improve our services and
            provide you with a better experience.
          </p>
          <p>
            To be able to sign up for activities, you need to provide us with
            your email address and phone number. This information is used to
            send you notifications and reminders about the activities you have
            signed up for. We also send this information to the activity
            organizers to help them manage the activities effectively. Any other
            information that is shared with the activity organizers is at your
            discretion.
          </p>
        </div>
        <div>
          <h2>Your Rights</h2>
          <p>
            Under the GDPR, you have the right to access, rectify, or erase your
            personal data. You also have the right to restrict or object to the
            processing of your personal data. You can exercise these rights by
            contacting us at
            <a href="mailto:post@klikkit.no">post @ klikkit.no</a>, though you
            also have all your personal data available in your profile page. We
            do not store any data that is not necessary for the operation of the
            service.
          </p>
        </div>
        <div>
          <h2>How do we protect your data? and How we handle Breaches</h2>
          <p>
            We take the security of your data very seriously and have
            implemented various technical and organizational measures to protect
            it. These include encryption, access controls, and regular security
            audits.
          </p>
          <p>
            We only use third-party services that are GDPR compliant and have
            strict data protection policies in place. Where required we also
            only use services that are inside of the European Economic Area
            (EEA). Making sure that your data is protected by the same laws and
            regulations as in the EU.
          </p>
          <div>
            <h3>Our Partners:</h3>
            <ul>
              <li>Stripe</li>
              <li>Netlify</li>
              <li>Heroku</li>
              <li>Twilio / Sendgrid</li>
              <li>Vipps Mobilepay</li>
              <li>Microsoft Azure</li>
            </ul>
          </div>
          <p>
            In the event of a data breach, we will notify you and the relevant
            authorities as soon as possible and take all necessary steps to
            mitigate the impact of the breach.
          </p>
          <p>
            Any sensitive data is never stored directly with our service, but is
            stored with our partners that are GDPR compliant. For instance, we
            use, among others, Stripe for payment processing, and they are GDPR
            compliant. We never store your credit card information. Any password
            you provide is hashed and salted before being stored in our
            database. We will never ask you for your password.
          </p>
        </div>
      </div>
      <Footer />
    </main>
  );
}
